import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputBlock5 from './InputBlock5';
import { useUpdateContactMutation } from "../store/apiSlice";

const OtherContactsRowEdit = ({ contact }) => {
  const [updateContact, { isLoadingUpdateJob, isUpdateSuccess, isError, error }] = useUpdateContactMutation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(contact);
  }, [contact]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    updateContact({ id: contact?.id, ...data });
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputBlock5
            type="text"
            field="firstName"
            label="First Name"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputBlock5
            type="text"
            field="lastName"
            label="Last Name"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputBlock5
            type="text"
            field="title"
            label="Title"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputBlock5
            type="text"
            field="email"
            label="Email"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputBlock5
            type="text"
            field="phone"
            label="Phone"
            register={register}
            errors={errors}
          />
        </div>
        <div className="relative">
          <span> HR </span>
          <input type="checkbox" {...register("hrYN")} />
          <div className="absolute top-full left-0 bg-white shadow-md rounded p-2 border border-gray-200 opacity-0 transition-opacity hover:opacity-100">
            <span className="text-gray-600 text-sm">This is a checkbox</span>
          </div>
        </div>
        <div>
          <span>Recruiter </span>
          <input type="checkbox" {...register("recruiterYN")} />
        </div>
        <div className="flex gap-2">
          <button type="submit" className="btn btn-xs">Save</button>
          <button
            type="button"
            className="btn btn-xs"
            onClick={(e) => onSubmit({ delete: true }, e)}
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  );
};

export default OtherContactsRowEdit;
