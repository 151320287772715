import React from "react";

export default function SignUpBenefits() {
  return (
    <div className="sign_up_benefits">
      <h1>Advertise with Australia's Best!</h1>
      <img
        src="https://www.teachingjobs.com.au/fall-in-love-with-something-new.webp"
        alt="Fall in Love with Something New at TeachingJobs"
        className="responsive-img mt-8"
      />

      {/* <section className="card flex gap-4 mb-8">
        <div className="image-container">
          <img
            src="/access.png"
            alt="Networking & Collaborating"
            className="responsive-img"
          />
        </div>
        <div className="text-container">
          <h5 className="font-semibold text-lg">Access a Global Talent Pool</h5>
          <p>
            Connect with top talent from diverse backgrounds and expertise
            across the globe. Collaborate with specialists in your industry to
            bring diverse perspectives and innovative solutions to your team.
          </p>
        </div>
      </section>

      <section className="card flex gap-4 mb-8">
        <div className="image-container">
          <img
            src="/streamline.png"
            alt="Launch your career."
            className="responsive-img"
          />
        </div>
        <div className="text-container">
          <h5 className="font-semibold text-lg">Streamline Recruitment Processes</h5>
          <p>
            Explore a vast pool of qualified candidates and streamline your
            recruitment process. With hundreds of job postings weekly, easily
            identify and match candidates based on their skills, experience, and
            cultural fit for your organization.
          </p>
        </div>
      </section>

      <section className="card flex gap-4 mb-8">
        <div className="image-container">
          <img
            src="/culture.png"
            alt="Find the right fit for you."
            className="responsive-img"
          />
        </div>
        <div className="text-container">
          <h5 className="font-semibold text-lg">Cultural Fit Assessment</h5>
          <p>
            Gain insights into a candidate's cultural fit before making hiring
            decisions. Experience firsthand how candidates align with your
            organization's values, mission, and tech culture, ensuring a
            seamless integration into your team.
          </p>
        </div>
      </section>

      <section className="card flex gap-4 mb-8">
        <div className="image-container">
          <img
            src="/development.png"
            alt="Get the career advice your are looking for."
            className="responsive-img"
          />
        </div>
        <div className="text-container">
          <h5 className="font-semibold text-lg">Professional Development Resources</h5>
          <p>
            Access valuable career insights and resources to support your hiring
            objectives. Stay informed with industry trends, gain access to
            career tools, and receive expert advice to enhance your recruitment
            strategies and attract top talent effectively.
          </p>
        </div>
      </section> */}
    </div>
  );
}
