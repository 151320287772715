import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setEmployer } from '../store/postsSlice';
import { useGetEmployerSuggestionsQuery } from "../store/apiSlice";

const UniSearchBlock = ({ field, register, customKey, value1, onChange, forceClass, onSelect }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [suburb, setSuburb] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);

  // Fetch suggestions based on query and suburb
  const { data: suggestions = [] } = useGetEmployerSuggestionsQuery(
    { query, suburb },
    {
      skip: !query, // Skip the query if 'query' is empty
    }
  );

  const handleInputClick = () => {
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (company_name, id, Region, country, logo, employerPageURL, clientType) => {
    setQuery(company_name);
    dispatch(setEmployer({ company_name, employer_id: id, logo, employerPageURL, clientType }));
    onChange(company_name, id || 0, Region, country, logo, employerPageURL, clientType);
    onSelect && onSelect(company_name, id || 0, Region, country, logo, employerPageURL, clientType);
    setShowSuggestions(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    setShowSuggestions(true);
    onChange(e.target.value, 0, "", "", "");
  };

  const handleSuburbChange = (e) => {
    setSuburb(e.target.value);
    setShowSuggestions(true);

  };

  useEffect(() => {
    setQuery(value1 || '');
  }, [value1]);

  return (
    <div className="w-full">
      <input
        {...register(`${field}-query`)}
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleQueryChange}
        onClick={handleInputClick}
        className={`w-full input input-bordered ${forceClass}`}
        autoComplete="off"
        name={`${customKey}-query`}
        placeholder="Enter employer name..."
      />
      <input
        {...register(`${field}-suburb`)}
        type="text"
        value={suburb}
        onChange={handleSuburbChange}
        className={`w-full input input-bordered ${forceClass} mt-2`}
        autoComplete="off"
        name={`${customKey}-suburb`}
        placeholder="Enter suburb..."
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className="mt-2 list-none bg-white border border-gray-300 rounded-md">
          {suggestions.map(({ company_name, id, Region, country, logo, employerPageURL, clientType, suburb, contactYN }, index) => (
            <li
              key={index}
              className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSuggestionClick(company_name, id, Region, country, logo, employerPageURL, clientType)}
            >
              <div>
                {company_name}
                {suburb ? ` (${suburb})` : ''}
                {!contactYN ? <span className="text-red-500"> - (No Contacts Available)</span> : ''}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UniSearchBlock;
