import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useAddOrderedProductsMutation, useCreateInvoiceMutation } from "../store/apiSlice";
import { useSelector } from "react-redux";
import InputBlock from '../components/InputBlock';
import swal from 'sweetalert';

const SavePayPost = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [loading, setLoading] = useState(false);
  const [addOrderedProducts, { isLoading, isSuccess, isError, error }] = useAddOrderedProductsMutation();
  const [standardMode, setStandardMode] = useState(true);
  const [newContact, setNewContact] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('creditCard');
  const [paymentMessage, setPaymentMessage] = useState('Credit Card');

  const { id = '1' } = useParams();
  let title = "";
  let xeroId;
  let description;
  let price;
  let currency = "AUD";
  let credits;

  switch (id) {
    case "1":
      title = "Single Job";
      description = "Single Job Posting on TeachingJobs.com.au";
      xeroId = "TJ-1";
      price = 188;
      credits = 1;
      break;
    case "2":
      title = "5 Job Pack";
      description = "5 Job Pack on TeachingJobs.com.au";
      xeroId = "TJ-5";
      price = 752;
      credits = 5;
      break;
    case "3":
      title = "10 Job Pack";
      description = "10 Job Pack on TeachingJobs.com.au";
      price = 1316;
      xeroId = "TJ-10";
      credits = 10;
      break;
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      paymentMethod: 'creditCard',
    },
  });

  const [createInvoice] = useCreateInvoiceMutation();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
        const invoiceReference = data['08_Invoice_Reference']
          ? data['08_Invoice_Reference']
          : `Card Payment: ${description} on ${new Date().toLocaleDateString('en-AU', {
            timeZone: 'Australia/Melbourne',
          })}`;

        const dueDate = paymentMethod === 'creditCard'
          ? new Date().toISOString().split('T')[0]
          : new Date(new Date().setDate(new Date().getDate() + 14)).toISOString().split('T')[0];

        const response = await createInvoice({
          product: {
            id: xeroId,
            description: `${description} - ${currency}`,
            price: price,
          },
          customerDetails: {
            name: data['01_First_Name'] + ' ' + data['01_Last_Name'],
            email: data['02_Email'],
            address: userInfo.orgnization_name,
          },
          currencyCode: currency,
          invoiceDetails: {
            address: {
              line1: data['address_line1'],
              line2: data['address_line2'],
              city: data['city'],
              region: data['region'],
              postalCode: data['postalCode'],
              country: data['country'],
            },
            reference: invoiceReference,
            dueDate: dueDate,
          },
        }).unwrap();

        if (response.status === 200) {
          addOrderedProducts({ userId: userInfo.id, productid: xeroId , jobCredits: credits });
          if (data.paymentMethod === 'creditCard') {
            window.open(response.data.onlineInvoiceUrl, '_blank');
          } else {
            swal("Thank you for your purchase!", "An invoice will be sent to you soon by email.", "success");
          }
        } 

    } catch (error) {
      setLoading(false);
      swal("There was an issue with your purchase.", "please try again.", "error");
      console.error('Error during invoice creation:', error);
    }
    setLoading(false);
  };

  return (
    <main className="">
      <div className="flex justify-center items-center">
        <div className="mx-auto w-full gap-16 justify-center">
          <div className='mt-8'>
            <span className={`text-2xl font-bold mb-8 mt-8 underline-full text-[#3b5683]`}>
              {`${title} - $${price} (${currency})`}{' '}<span className="text-xs">exc GST</span>
            </span>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col md:flex-row gap-2 mt-4 mb-4">
                <div className="grid w-full items-center">
                  <InputBlock
                    register={register}
                    errors={errors}
                    label="First Name"
                    type="text"
                    field="01_First_Name"
                    forceClass=" text-black"
                    placeholder="First Name"
                    autoComplete="given-name"
                    hidden={newContact || standardMode ? false : true}
                    required={newContact || standardMode ? true : false}
                  />
                </div>
                <div className="grid w-full items-center">
                  <InputBlock
                    register={register}
                    errors={errors}
                    label="Last Name"
                    type="text"
                    field="01_Last_Name"
                    forceClass=" text-black"
                    placeholder="Last Name"
                    autoComplete="family-name"
                    hidden={newContact || standardMode ? false : true}
                    required={newContact || standardMode ? true : false}
                  />
                </div>
                <div className="grid w-full items-center">

                  <InputBlock
                    register={register}
                    errors={errors}
                    label="Email"
                    type="email"
                    field="02_Email"
                    forceClass=" text-black"
                    placeholder="email"
                    autoComplete="email"
                    hidden={newContact || standardMode ? false : true}
                    required={true}
                  />
                </div>
              </div>


                <>
                  <label className="form-control">
                    <span className="label-text text-xs pb-1">Billing Address</span>
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputBlock
                      register={register}
                      errors={errors}
                      label="Address Line 1"
                      type="text"
                      field="address_line1"
                      forceClass="text-black"
                      placeholder="Address Line 1"
                      required={true}
                    />
                    <InputBlock
                      register={register}
                      errors={errors}
                      label="Address Line 2"
                      type="text"
                      field="address_line2"
                      forceClass="text-black"
                      placeholder="Address Line 2"
                      required={false}
                    />
                    <InputBlock
                      register={register}
                      errors={errors}
                      label="City"
                      type="text"
                      field="city"
                      forceClass="text-black"
                      placeholder="City"
                      required={true}
                    />
                    <InputBlock
                      register={register}
                      errors={errors}
                      label="Region"
                      type="text"
                      field="region"
                      forceClass="text-black"
                      placeholder="Region"
                      required={true}
                    />
                    <InputBlock
                      register={register}
                      errors={errors}
                      label="Postal Code"
                      type="text"
                      field="postalCode"
                      forceClass="text-black"
                      placeholder="Postal Code"
                      required={true}
                    />
                    <InputBlock
                      register={register}
                      errors={errors}
                      label="Country"
                      type="text"
                      field="country"
                      forceClass="text-black"
                      placeholder="Country"
                      required={true}
                    />
                  </div>
                  {paymentMethod === 'Invoice' && (
                    <InputBlock
                      register={register}
                      errors={errors}
                      label="Invoice Reference (for your records)"
                      type="text"
                      field="08_Invoice_Reference"
                      forceClass="text-black mt-4"
                      placeholder="Invoice Reference"
                      required={true}
                    />
                  )}
                </>
              
              <div className="flex flex-col gap-4 justify-start">
                <div className="flex gap-4">
                  <label htmlFor="creditCard" className="label cursor-pointer">
                    <strong className="mr-2">Payment method:</strong>Credit Card (Pay Now)
                    <input
                      type="radio"
                      id="creditCard"
                      name="paymentMethod"
                      value="creditCard"
                      {...register('paymentMethod')}
                      onClick={() => { setPaymentMessage('Credit Card'); setPaymentMethod('Credit Card'); }}
                      className="radio radio-aj ml-2"
                    />
                  </label>

                  <label htmlFor="invoice" className="label cursor-pointer">
                    Invoice (Pay Later)
                    <input
                      type="radio"
                      id="invoice"
                      name="paymentMethod"
                      value="invoice"
                      {...register('paymentMethod')}
                      onClick={() => { setPaymentMessage('Invoice'); setPaymentMethod('Invoice'); }}
                      className="radio radio-aj ml-2"
                    />
                  </label>

                </div>
                <button className="btn bg-green-300 mt-4 max-w-[300px] mx-auto" disabled={loading}>
                {loading ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing...
                  </div>
                ) : (
                  'Pay via ' + paymentMessage
                )}
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SavePayPost;
