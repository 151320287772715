import { useSelector } from "react-redux";
import React from "react";
import { useGetSalesDashboardByUserQuery } from "../store/apiSlice";
import EmployersSalesInfo from "../components/SalesNotesByUser";
import { useGetAUNZEmployersSalesLeadsQuery } from "../store/apiSlice";
import EmployersListSalesLeads from "../components/EmployersListSalesLeads";

function AdminHome(props) {
  const userInfo = useSelector((state) => state.auth.userInfo);

  const {
    data: AuNzEmployers,
    isLoading: isLoadingAuNzEmployers,
    isSuccess: isSuccessAuNzEmployers,
    isError: isErrorAuNzEmployers,
  } = useGetAUNZEmployersSalesLeadsQuery();

  const {
    data: dataSalesNotes,
    isLoading: isLoadingSalesNotes,
    isSuccess: isSuccessSalesNotes,
    isError: isErrorSalesNotes,
  } = useGetSalesDashboardByUserQuery({ id: userInfo.id });

  const AuNZSalesLeads = isSuccessAuNzEmployers && (
    <div className="flex">
      <div className="w-full ">
        <EmployersListSalesLeads data={AuNzEmployers} />
      </div>
    </div>
  );

  if (isLoadingSalesNotes || isLoadingAuNzEmployers) {
    return <div>Loading...</div>;
  }

  if (isErrorSalesNotes || isErrorAuNzEmployers) {
    return <div>Error loading sales data.</div>;
  }

  console.log(AuNzEmployers)

  return (
    <div>
      <h1 className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mt-4 mb-6 ml-6">
        Welcome {userInfo.firstName} !
      </h1>
      {dataSalesNotes && (
        <div className="flex">
          <div className="w-full">
            <EmployersSalesInfo data={dataSalesNotes} />
          </div>
        </div>
      )}
      {userInfo.id === 162 && (
        <div>
          {AuNZSalesLeads}
        </div>
      )}
    </div>
  );
  
}

export default AdminHome;
