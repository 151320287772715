import React, { useState, useEffect } from 'react';
import { useGetAllUsersQuery, useRegisterUserMutation } from './auth/authApiSlice';
import { useGetEmployerQuery } from '../store/apiSlice';

const UserList = ({ id, userType, employer_name, employer_details }) => {
  console.log(id)
  const { data, error, isLoading } = useGetAllUsersQuery(id);
  const [registerUser, { isLoading: isRegistering }] = useRegisterUserMutation();
  
  const [formData, setFormData] = useState({
    userType: userType,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    orgnization_name: employer_name,
    country: employer_details?.country,
    state: employer_details?.state,
    postCode: employer_details?.postalCode,
    phone: '',
    employer_id: id,
  });

  
  const [message, setMessage] = useState('');
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await registerUser(formData).unwrap();
      setMessage('User successfully registered!');
      setFormData({
        userType,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        orgnization_name: employer_name,
        country: employer_details?.country,
        state: employer_details?.state,
        postCode: employer_details?.postalCode,
        phone: '',
        employer_id: id,
      });
    } catch (error) {
      console.error(error);
  
      // Handle different error response formats gracefully
      const errorMessage =
        error?.data?.message || error.message || 'An unexpected error occurred';
  
      setMessage(`Error registering user: ${errorMessage}`);
    }
  };
  
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-h-[600px] overflow-y-auto custom-scrollbar">
        <h2 className="text-xl font-semibold text-center mb-4">
          {userType === 'employee' ? 'Employees' : 'Users'}
        </h2>
        <ul className="list-none">
          {userType === 'employee' &&
            data?.employees?.map((employee) => (
              <li key={employee.id} className="py-2 border-b border-gray-200">
                <p className="text-gray-700 font-medium">
                  {employee.firstName} {employee.lastName}
                </p>
                <p className="text-gray-500 text-sm">{employee.email}</p>
              </li>
            ))}
          {userType === 'employer' &&
            data?.employers?.map((employer) => (
              <li key={employer.id} className="py-2 border-b border-gray-200">
                <p className="text-gray-700 font-medium">
                  {employer.firstName} {employer.lastName}
                </p>
                <p className="text-gray-500 text-sm">{employer.email}</p>
                {employer.Employer ? (
                  <p className="text-gray-600 text-sm mt-2">
                    <strong>Company: </strong>
                    {employer.Employer.company_name}
                  </p>
                ) : (
                  <p className="text-gray-600 text-sm mt-2">
                    <strong>Company: </strong> Not associated with a company
                  </p>
                )}
              </li>
            ))}
        </ul>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 w-full mx-auto mt-8">
        <h2 className="text-xl font-semibold text-center mb-4">Register New User</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <select
            name="userType"
            value={formData.userType}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select User Type</option>
            <option value="employee">Employee</option>
            <option value="employer">Employer</option>
          </select>

          <div className="flex space-x-4">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div className="flex space-x-4">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {userType === 'employer' && (
            <>
              <input
                type="text"
                name="employer_id"
                placeholder="Employer ID"
                value={formData.employer_id}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                name="orgnization_name"
                placeholder="Organization Name"
                value={formData.orgnization_name}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md"
                readOnly
              />
            </>
          )}

          <div className="flex space-x-4">
            <input
              type="text"
              name="country"
              placeholder="Country"
              value={formData.country}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={formData.state}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="flex space-x-4">
            <input
              type="text"
              name="postCode"
              placeholder="Post Code"
              value={formData.postCode}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          >
            Register User
          </button>
        </form>
        {message && (
          <p
            className={`mt-4 text-center ${
              message.includes('success') ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default UserList;
