import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import JobList1 from './JobList1';
import { useNavigate } from 'react-router-dom';
import { useGetJobs2Query, useGetQty1Query } from '../store/apiSlice';
import { setJob } from '../store/postsSlice';
import { BsHandbag } from 'react-icons/bs';

const SearchResults2 = ({ q, filters }) => {
  const [viewCurrent, setViewCurrent] = useState(true);
  const [q1, setQ1] = useState(q);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(filters ? filters : []); // State to hold selected filters
  const navigate = useNavigate();
  const job = useSelector((state) => state.posts.job);
  const componentRef = useRef(null);

  useLayoutEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollTop = 0;
    }
  }, [job]);

  const {
    data: qty,
    isLoading: isLoadingQty,
    isSuccess: isSuccessQty,
  } = useGetQty1Query(q);
  const [page, setPage] = useState(0);

  function nextPage() {
    console.log('nextPage');
    setPage((prev) => prev + 1);
  }

  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useGetJobs2Query({ ...q1, startDate, endDate, page, selectedFilters: selectedFilters });

  useEffect(() => {
    if (data) dispatch(setJob(data[0]));
  }, [data, dispatch]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') setStartDate(value);
    if (name === 'endDate') setEndDate(value);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilters((prev) => {
      // Toggle filter: add if not present, remove if already present
      if (prev.includes(filter)) {
        return prev.filter((f) => f !== filter);
      } else {
        return [...prev, filter];
      }
    });
  };

  let content;
  if (isLoading) {
    content = (
      <div className='flex  w-full h-screen  justify-between '>
        <div role="status" className=" h-screen p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
        <div role="status" className="w-[60%] h-screen p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  } else if (isSuccess) {
    content = (
      <div className="mt-6 font-bold gap-2">
        <div className="w-full mb-2 flex gap-2">
          <BsHandbag />
          <span>Jobs - </span>
          {qty?.currentQty && (
            <span
              className={`cursor-pointer ${viewCurrent && 'underline'}`}
              onClick={() => {
                setViewCurrent(true);
                setQ1((p) => ({ ...p, expired: "0" }));
              }}
            >{`Current ( ${qty?.currentQty} | ${qty?.currentSalesLeadsQty} )`}</span>
          )}
          <span>|</span>
          {qty?.expiredQty && (
            <span
              className={`cursor-pointer ${viewCurrent || 'underline'}`}
              onClick={() => {
                setViewCurrent(false);
                setQ1((p) => ({ ...p, expired: "1" }));
              }}
            >{`Expired ( ${qty?.expiredQty} | ${qty?.expiredSalesLeadsQty} )`}</span>
          )}
        </div>
        <div className="w-full mb-2 flex gap-2">
          <button className='btn btn-xs'
            onClick={() => {
              setQ1(p => ({ ...p, sort: "date" }))
            }}
          >
            Sort By Expiration Date
          </button>
          <div className="w-full mb-2 flex gap-2">
            <button
              className={`btn btn-xs ${selectedFilters.includes("salesLeads") ? 'bg-blue-500 text-white' : ''}`}
              onClick={() => handleFilterClick("salesLeads")}
            >
              Sales Leads
            </button>
            <button
              className={`btn btn-xs ${selectedFilters.includes("noSalesLeads") ? 'bg-blue-500 text-white' : ''}`}
              onClick={() => handleFilterClick("noSalesLeads")}
            >
              Sales Leads Required
            </button>
            <button
              className={`btn btn-xs ${selectedFilters.includes("withSalesNote") ? 'bg-blue-500 text-white' : ''}`}
              onClick={() => handleFilterClick("withSalesNote")}
            >
              No Sales Notes
            </button>
          </div>
          <input
            type="date"
            name="startDate"
            value={startDate}
            onChange={handleDateChange}
            className="input-date"
          />
          <input
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleDateChange}
            className="input-date"
          />
          <button
            className="btn btn-xs"
            onClick={() => {
              setQ1((p) => ({ ...p, startDate, endDate }));
            }}
          >
            Filter By Expiration Date
          </button>
        </div>
        <div className="overflow-y-auto mt-4">
          <JobList1 data={data} nextPage={nextPage} />
        </div>
      </div>
    );
  }

  return <div className="relative overflow-y w-full">{content}</div>;
};

export default SearchResults2;
