import React from 'react';
import PasswordReset from '../../components/PasswordReset';

const ResetPasswordPage = () => {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <PasswordReset userId={null} />
        </div>
    );
};

export default ResetPasswordPage;