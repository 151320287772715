import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom'; 
import { useUpdateUserPasswordMutation } from '../views/auth/authApiSlice';
import swal from 'sweetalert';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

const PasswordReset = ({ userId }) => {
  const [formData, setFormData] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const id = searchParams.get('id');

  const [currentUserId, setCurrentUserId] = useState(userId);
  const navigate = useNavigate();

  // Hook to call the mutation
  const [updateUserPassword, { isLoading, isSuccess, isError, error }] =
    useUpdateUserPasswordMutation();

  useEffect(() => {
    if (!userId && id) {
      setCurrentUserId(id);
    }
  }, [userId, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      swal('Error', 'Passwords do not match', 'error');
      return;
    }

    const formDataToSend = {
      userId: currentUserId || id,
      password: formData.password,
      ...(token && { token }),
      ...(userId && { currentPassword: formData.currentPassword }),
    };

    try {
      await updateUserPassword(formDataToSend).unwrap(); // Execute mutation and unwrap response
      swal('Success', 'Password updated successfully', 'success').then(() => {
        if (!userId) {
          navigate('/'); // Navigate to home if userId is not present
        }
      });
    } catch (err) {
      console.error('Error updating password:', err);
      swal('Error', 'Failed to update password', 'error');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="">
      {userId && (
        <div className="mb-1">
          <label className="block mb-1">Current Password</label>
          <div className="relative">
            <input
              type={showCurrentPassword ? 'text' : 'password'}
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
              className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
            />
            <button
              type="button"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              className="absolute inset-y-0 right-0 px-3 text-sm text-gray-600 focus:outline-none"
            >
              {showCurrentPassword ? (
                <EyeOffIcon className="h-5 w-5 text-gray-400" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" />
              )}
            </button>
          </div>
        </div>
      )}
      <div className="mb-1">
        <label className="block mb-1">New Password</label>
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 px-3 text-sm text-gray-600 focus:outline-none"
          >
            {showPassword ? (
              <EyeOffIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-400" />
            )}
          </button>
        </div>
      </div>
      <div className="mb-2">
        <label className="block mb-1">Confirm Password</label>
        <div className="relative">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute inset-y-0 right-0 px-3 text-sm text-gray-600 focus:outline-none"
          >
            {showConfirmPassword ? (
              <EyeOffIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-400" />
            )}
          </button>
        </div>
      </div>
      <div className="flex justify-between">

        {userId && (
          <Link to="/forgot-password" className="text-blue-500 hover:underline mt-2">
            Forgot Password.
          </Link>
        )}
                <button
          type="submit"
          className="py-2 px-4 mt-2 btn btn-aj"
          disabled={isLoading}
        >
          {isLoading ? 'Updating...' : 'Update Password'}
        </button>
      </div>
    </form>
  );
};

export default PasswordReset;
