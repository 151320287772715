import React, { useState, useEffect, useRef } from "react"
import {
    useAddOtherContactsMutation,
} from "../store/apiSlice"
import { useForm, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import InputBlock4 from "./InputBlock4"
const AddOtherContacts = ({ id }) => {
    console.log("=====AddOtherContacts==========", id)
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [logo, setLogo] = useState('')
    useEffect(() => {
        return () => {
        }
    }, [])
    let content
    const [AddOtherContacts1] = useAddOtherContactsMutation()
    const {
        control,
        register,
        reset,
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: { errors },
        setError,
    } = useForm({
    });
    const onSubmit = async (data) => {
        console.log("data", { ...data, employer_id: id });
        if (data) AddOtherContacts1({ ...data, employer_id: id })
    };
    return (
        <div className="overflow-y w-full">
            < form onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-2"
            >
                <div className="flex gap-4">
                    <label className="w-[30%] text-right  font-bold">First Name</label>
                    <div className="w-[50%] text-left">
                        <InputBlock4
                            type="text"
                            field="firstName"
                            label="firstName"
                            register={register}
                            errors={errors}
                            forceClass=""
                        />
                    </div>
                </div>
                <div className="flex gap-4">
                    <label className="w-[30%] text-right  font-bold">Last Name</label>
                    <div className="w-[50%] text-left">
                        <InputBlock4
                            type="text"
                            field="lastName"
                            label="lastName"
                            register={register}
                            errors={errors}
                            forceClass=""
                        />
                    </div>
                </div>
                <div className="flex gap-4">
                    <label className="w-[30%] text-right  font-bold">Job Title</label>
                    <div className="w-[50%] text-left">
                        <InputBlock4
                            type="text"
                            field="title"
                            label="title"
                            register={register}
                            errors={errors}
                            forceClass=""
                        />
                    </div>
                </div>
                <div className="flex gap-4">
                    <label className="w-[30%] text-right  font-bold">Email</label>
                    <div className="w-[50%] text-left">
                        <InputBlock4
                            type="text"
                            field="email"
                            label="email"
                            register={register}
                            errors={errors}
                            forceClass=""
                        />
                    </div>
                </div>
                <div className="flex gap-4">
                    <label className="w-[30%] text-right  font-bold">Phone Number</label>
                    <div className="w-[50%] text-left">
                        <InputBlock4
                            type="text"
                            field="phone"
                            label="phone"
                            register={register}
                            errors={errors}
                            forceClass=""
                        />
                    </div>
                </div>
                <button
                    className='w-1/2 btn items-center m-auto'
                >Add New Contacts</button>
            </form>
        </div>
    )
}
export default AddOtherContacts