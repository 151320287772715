import React from 'react';
import { Helmet } from "react-helmet";
const DiversayStatement = () => {  
    return (
        <div className="">
            <Helmet>
                <title>Diversity Statement Science Jobs</title>
                <meta name="description" content="Discover a diverse and inclusive Science job board site committed to equity and accessibility. Explore opportunities that value and embrace diversity. " />
                <meta name="keywords" content="Diversity Statement Science Jobs, ScienceJobs Diversity Statement" />
            </Helmet>
            <div className="relative">
                <div
                    className="bg-cover bg-center bg-repeat"
                >
                    <div className="px-7 py-8 max-w-screen-xl mx-auto py-12 px-6 text-gray-700">
                        <h1 className="text-sky-500 text-2xl font-semibold">Diversity Statement</h1>
                        <p>
                            ScienceJobs’ purpose is to match the right people to the right jobs. We put job seekers and employers at the heart of everything we do. We are committed to creating an environment where everyone can bring their authentic selves to work and allow others to do the same.
                            A diverse mix of minds, backgrounds, and experiences, and an inclusive work environment leads to excellence.  ScienceJobs provides equal opportunities to all employees and applicants for employment, without regard to race, religion, color, age, sex, national origin, sexual orientation, gender identity, genetic disposition, neurodiversity, disability, veteran status, or any other protected category under federal, state and local law.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DiversayStatement;