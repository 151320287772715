import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm } from "react-hook-form";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  useGetCampusesMutation,
  useGetEmployerSuggestionsQuery,
  useCreateEmployerMutation,
  useGetEmployerQuery,
  useUpdateEmployerMutation,
  useGetEmployerByIdQuery,
} from "../store/apiSlice";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import InputBlock2 from "./InputBlock2";
import InputBlock4 from "./InputBlock4";
import SelectBlock2 from "./SelectBlock2";
import SelectBlock3 from "./SelectBlock3";
import UniSearchBlock from "./UniSearchBlock";
import SelectBlockNew from "./SelectBlockNew";
import SelectBlock1 from "./SelectBlock1";
import SalesNotes from "./SalesNotes";
import OtherContacts from "./OtherContacts";
import { useNavigate, Link, NavLink } from "react-router-dom";
//import { setemployer} from "./postSlice"
import { regions } from "../utils/data";
import { setId, setEmployer } from "../store/postsSlice";
import CreateInvoice from "../components/CreateInvoice";
import ImageResize from 'quill-image-resize-module-react';
import AddEditUsers from "./AddEditUsers";


const users = [
  {
    id: 76,
    firstName: "Mary Rose Joana",
    portrait: "mary_rose_joana_pecson.jpg",
  },
  {
    id: 77,
    firstName: "Joan",
    portrait: "joan_santos.jpg",
  },
  {
    id: 182,
    firstName: "Clarissa",
    portrait: "ClarissaPacetes.png"
  },
  {
    id: 79,
    firstName: "Fausto",
    portrait: "fausto_san_juan.jpg",
  },
  {
    id: 81,
    firstName: "Jhon Michael",
    portrait: "jhon_michael_cruz.jpg",
  },
  {
    id: 82,
    firstName: "Bianca",
    portrait: "Bianca_Cacho_ID Picture copy.jpg",
  },
  {
    id: 83,
    firstName: "Laira Andrea",
    portrait: "laira_andrea_austria.jpg",
  },
  {
    id: 184,
    firstName: "Rencel Velasco",
    portrait: "RencelVelasco.jpeg"
  },
  {
    id: 86,
    firstName: "Don",
    portrait: "don_fernandez.jpg",
  },
  {
    id: 4,
    firstName: "Jarrod Kanizay",
    portrait: "jarrod_kanizay.webp",
  }
];
const validationSchema = yup
  .object({
    // username: yup.string().required('Missing username'),
    // email: yup.string().required('Missing email').email('Invalid email format'),
    //password: yup.string().required('Missing password'),
  })
  .required();
const EmployerAddEdit = ({ employer }) => {
  window.Quill = Quill
  Quill.register('modules/imageResize', ImageResize);
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  const formats = ['image'];
  // const modules = {
  //   toolbar: [
  //     [{ 'header': [1, 2, false] }, { 'font': [] }],
  //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //     [{ 'list': 'ordered' }, { 'list': 'bullet' },
  //     { 'indent': '-1' }, { 'indent': '+1' }],
  //     ['link', 'image', 'video'],
  //     ['clean']
  //   ]
  // }

  const location = useLocation();
  console.log("=====employer=============", employer);
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || "profile");
  const [activeJobId, setActiveJobId] = useState(location.state?.activeJobId || null);
  const googleAddressRef = useRef();
  const isAddMode = !employer;
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const regions = [
    "Australia",
    "Asia",
    "Africa",
    "New Zealand",
    "Canada",
    "Europe",
    "India",
    "Global",
    "United States",
    "United Kingdom",
  ];
  console.log("EditEmployerProfile");
  const [logo, setLogo] = useState("")
  const onUploadAvatar = async (event) => {
    // Call API to BE to generate a pre-signed url to upload file object
    console.log("event.target.files[0].name", event.target.files[0].name)
    const response = await fetch('https://api.sciencejobs.com.au/api/generate-upload-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Specify the content type as JSON
      },
      body: JSON.stringify({ fileName: event.target.files[0].name, directory: 'university-logo' })
    }) //'https://api2.sciencejobs.com.au/api/generate-upload-url'
    const { presignedUploadUrl } = await response.json();
    console.log(presignedUploadUrl)
    // Use the pre-signed upload url to upload the image
    const imageResponse = await fetch(presignedUploadUrl, {
      method: 'PUT', // or 'POST' depending on your server requirements
      body: event.target.files[0]
    });
    setLogo(event.target.files[0].name)
    setValue("logo", event.target.files[0].name);
    console.log(event.target.files[0].name)
    //console.log(imageResponse)
    //console.log(imageResponse.json())
    //const { url } = await imageResponse.json();
    //console.log(url)
    // Trigger onChange callback of react-hook-form to set uploaded image url string to form
    //onChange(event.target.files[0].name);
  }
  const employerCurrent = useSelector((state) => state.posts.employer);
  let defaultValues
  const { register, reset, handleSubmit, setValue, watch, formState: { errors }, setError, } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues])
  });
  const { onChange, ...avatarField } = register('avatar');
  const [employerStatus, setEmployerStatus] = useState('Post Employer');
  const editorContent = watch("description");
  const editorContent1 = watch("employer_name");
  const [query, setQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true);
  const inputRef = useRef();
  const submitRef = useRef();
  const countryRef = useRef('');
  const { data: suggestions = [] } = useGetEmployerSuggestionsQuery({ query, country: countryRef.current || "" }, {
  });
  const [updateEmployer, { isLoading: isLoadingUpdateEmployer, isSuccess: isSuccessUpdateEmployer, isError: isErrorUpdateEmployer, error: errorUpdateEmployer, }] =
    useUpdateEmployerMutation();
  const [createEmployer, { data, isLoading: isLoadingCreateEmployer, isSuccess: isSuccessCreateEmployer, isError: isErrorCreateEmployer, error: errorCreateEmployer }] = useCreateEmployerMutation();
  const [newList, setNewList] = useState([]);
  const [newListName, setNewListName] = useState(null);
  const [newList1, setNewList1] = useState([]);
  const [newList1Name, setNewList1Name] = useState(null);
  const userid = useSelector((state) => state.auth.userid);
  const country = useSelector((state) => state.auth.country);
  const company_name = useSelector((state) => state.auth.company_name);
  const navigate = useNavigate();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (employer) {
      setLogo(employer?.logo)
      reset(employer);
    } else {
      reset({ ranking: "3", });
      setValue("clientType", 'HeadlineOnly');
    }
    setValue('postedBy', userInfo.id)
  }, [employer]);
  useEffect(() => {
    register("description", { required: false });
    register("company_name1", { required: false });
    register("country", { required: false });
  }, [register]);
  const onEditorStateChange = (editorState) => {
    setValue("description", editorState);
  };
  const onEditorStateChange1 = (company_name, employer_id, Region, country, logo) => {
    console.log("======onEditorStateChange1=====", company_name, employer_id, Region, country, logo)
    setValue("company_name", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    dispatch(setEmployer({ company_name, employer_id, logo }))
    if (employer_id) getCampuses({ id: employer_id })
  }
  function findValueForKey(array, keyToFind) {
    for (const obj of array) {
      const keys = Object.keys(obj);
      if (keys.includes(keyToFind)) {
        return obj[keyToFind];
      }
    }
    return null;
  }
  const [getCampuses, { data: dataCampuses }] = useGetCampusesMutation();
  let content, content1;
  const recordRef = useRef({ employer_id: userid });
  // const handleChange1 = (newValue) => {
  //   console.log(newValue, findValueForKey(employer_category, newValue))
  //   setNewList(findValueForKey(employer_category, newValue));
  //   setNewListName(newValue);
  //   setNewList1([]);
  //   setNewList1Name(null);
  // };
  // const handleChange2 = (newValue, flag) => {
  //   console.log(newValue, flag, findValueForKey(employer_category, newValue))
  //   recordRef.current = { ...recordRef.current, [flag]: newValue };
  //   console.log(recordRef.current);
  //   setNewList1(findValueForKey(employer_category, newValue));
  //   setNewList1Name(newValue);
  //   setValue(categoryCovert[newListName], newValue);
  // };
  const handleChange3 = (newValue, flag) => {
    recordRef.current = { ...recordRef.current, [flag]: newValue };
    console.log(recordRef.current);
  };
  const handleSalesNoteChange = () => {
    setValue("salesNoteUpdateDate", new Date());
  };
  const onSubmit = async (data) => {
    console.log('data', data)
    return isAddMode
      ? createEmployer1(data)
      : updateEmployer1({ id: employer?.id, ...data })
  }
  async function createEmployer1(data) {
    const response = await createEmployer(data);
    navigate("/employers-center/");
  }
  async function updateEmployer1(data) {
    const response = await updateEmployer(data);
    navigate(`/employers-center/${data.id}/`);
    window.scrollTo(0, 0);
  }
  content = (

    <div className="w-full flex flex-col gap-4 pb-16">
      <div className="rounded-b-xl px-4 mx-4 pb-8 gap-4 flex justify-center">
        <button
          className={`tab btn relative ${activeTab === "profile" ? "active" : ""}`}
          onClick={() => setActiveTab("profile")}
        >
          Profile
          {activeTab === "profile" && (
            <span className="switch absolute top-full left-1/2 transform -translate-x-1/2 mt-1 w-full h-1 bg-amber-500"></span>
          )}
        </button>
        {employer && (
          <><button
            className={`tab btn relative ${activeTab === "sales" ? "active" : ""}`}
            onClick={() => setActiveTab("sales")}
          >
            Sales
            {activeTab === "sales" && (
              <span className="switch absolute top-full left-1/2 transform -translate-x-1/2 mt-1 w-full h-1 bg-amber-500"></span>
            )}
          </button><button
            className={`tab btn relative ${activeTab === "invoice" ? "active" : ""}`}
            onClick={() => setActiveTab("invoice")}
          >
              Invoicing
              {activeTab === "invoice" && (
                <span className="switch absolute top-full left-1/2 transform -translate-x-1/2 mt-1 w-full h-1 bg-amber-500"></span>
              )}
            </button>
            <button
              className={`tab btn relative ${activeTab === "users" ? "active" : ""}`}
              onClick={() => setActiveTab("users")}
            >
              Users
              {activeTab === "users" && (
                <span className="switch absolute top-full left-1/2 transform -translate-x-1/2 mt-1 w-full h-1 bg-amber-500"></span>
              )}
            </button>
          </>
        )}

      </div>

      {activeTab === "profile" && (
        <div className="profile-tab">

          <form className='flex w-full flex-col' onSubmit={handleSubmit(onSubmit)}>
            {defaultValues?.id > 0 &&
              <Link to={`/employers/university/${defaultValues?.id}/`}
                className="btn ">
                {`https://academicjobs.com/employers/university/${defaultValues?.id}/`}
              </Link>}
            <div className="flex w-full flex-col gap-2 " >

              <div>
              </div>
              {/* <div className="flex gap-4">
            <label className="w-[30%] text-right  font-bold">Institution Name *</label>
            <div className="w-[50%] text-left">
              <UniSearchBlock
                register={register}
                field="company_name"
                customKey="company_name"
                label="company_name"
                value1={employer?.company_name}
                forceClass="mb-6"
                onChange={onEditorStateChange1}
              />
            </div>
          </div> */}


              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Institution Name
                </label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="company_name" label="company_name" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right text-lg text-red-600 font-bold">
                  Institution Location
                </label>
                <div className="w-[50%] text-left">
                  <Autocomplete
                    className="border-2 rounded mt-2 text-pink-400"
                    // defaultValue="Start typing job location & allow Google to auto-complete"
                    defaultValue={employer?.location}
                    style={{ width: "100%" }}
                    ref={googleAddressRef}
                    apiKey="AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA"
                    {...register("location1")}
                    onPlaceSelected={(place) => {
                      console.log("Selected Place:", place);
                      // Extracting address components
                      const addressComponents = place.address_components || [];
                      // Initialize variables for city, state, and country
                      let suburb = "";
                      let state = "";
                      let country = "";
                      let postalCode = "";
                      // Iterate through address components to find relevant information
                      addressComponents.forEach((component) => {
                        const types = component.types || [];
                        if (types.includes("locality")) {
                          suburb = component.long_name;
                        } else if (types.includes("administrative_area_level_1")) {
                          state = component.long_name;
                        } else if (types.includes("country")) {
                          country = component.long_name;
                        } else if (types.includes("postal_code")) {
                          postalCode = component.long_name;
                        }
                      });
                      setValue("location", place.formatted_address);
                      setValue("suburb", suburb);
                      setValue("state", state);
                      setValue("country", country);
                      setValue("Region", country);
                      setValue("postalCode", postalCode);
                    }}
                    // onPlaceSelected={(selected, a, c) => {
                    //   console.log(selected,a,c);
                    //   setValue("location", selected)
                    // }}
                    options={{
                      types: ["geocode", "establishment"],
                      //componentRestrictions: { country: "Australia" },
                    }}
                    //onPlaceSelected={(place) =>
                    //formik.setFieldValue("countryAnother", place.formatted_address)
                    //}
                    onChange={(e) => setValue("location", e.target.value)}
                  //onChange={e => console.log(e)}
                  />
                </div>
              </div>
              <div className="gap-4 hidden">
                <label className="w-[30%] text-right  font-bold">Acronym
                </label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="acronym" label="Acronym" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Featured
                </label>
                <div className="w-[50%] text-left">
                  <input type="checkbox" {...register("featured1")} />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Ranking</label>
                <div className="w-[50%] text-left">
                  <div className="flex items-center justify-start">
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="1"
                          onChange={(e) => {
                            const selectedValue = +e.target.value; // Using the unary plus operator to convert to a number
                            // Now `selectedValue` will be a number, not a string
                            // You can use it as needed
                          }}
                        />
                        <span className="label-text text-xs">1</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="2"
                        />
                        <span className="label-text text-xs">2</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2  mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="3"
                        />
                        <span className="label-text text-xs">3</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="4"
                        />
                        <span className="label-text text-xs">4</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="5"
                        />
                        <span className="label-text text-xs">5</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Employer Type
                </label>
                <div className="w-[50%] flex flex-col gap-2 text-left">
                  <div className="flex gap-3">
                    <div className="flex gap-1">
                      <label className="text-sm text-right  font-bold">Unlimited
                      </label>
                      <div className=" text-left">
                        <input type="checkbox" {...register('unlimitedYN')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setValue("ranking", "5");
                              setValue("clientType", 'HeadlineOnly');
                            } else {
                              setValue("ranking", "3");
                              setValue("clientType", 'HeadlineOnly');
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <label className="text-sm text-right  font-bold">Region Top Uni
                      </label>
                      <div className=" text-left">
                        <input type="checkbox" {...register('top20University')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setValue("ranking", "5");
                              setValue("clientType", 'freeListing');
                            } else {
                              setValue("ranking", "3");
                              setValue("clientType", 'HeadlineOnly');
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <label className="text-sm text-right  font-bold">Catholic Dioces
                      </label>
                      <div className=" text-left">
                        <input type="checkbox" {...register('catholicDiocesYN')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setValue("ranking", "5");
                              setValue("clientType", 'HeadlineOnly');
                            } else {
                              setValue("ranking", "3");
                              setValue("clientType", 'HeadlineOnly');
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <div className="flex gap-1">
                      <label className="text-sm text-right  font-bold">Sales Lead Client
                      </label>
                      <div className=" text-left">
                        <input type="checkbox" {...register('salesLeadClientYN')}

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Job Listing Type</label>
                <div className="w-[50%] text-left">
                  <div className="flex items-center justify-start">
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("clientType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="unlimited"
                        />
                        <span className="label-text text-xs">Unlimited</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("clientType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="jobPadding"
                        />
                        <span className="label-text text-xs">Job Padding(FREE)</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("clientType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="paidSingleListing"
                        />
                        <span className="label-text text-xs">Paid Single Listing</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("clientType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="HeadlineOnly"
                        />
                        <span className="label-text text-xs">Headline Only</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">School Affiliation</label>
                <div className="w-[50%] text-left">
                  <div className="flex items-center justify-start">
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolAffiliation")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Public"
                        />
                        <span className="label-text text-xs">Public</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolAffiliation")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Private"
                        />
                        <span className="label-text text-xs">Private</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolAffiliation")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Catholic"
                        />
                        <span className="label-text text-xs">Catholic</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolAffiliation")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Independent"
                        />
                        <span className="label-text text-xs">Independent</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolAffiliation")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Christian"
                        />
                        <span className="label-text text-xs">Christian</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">School Type</label>
                <div className="w-[50%] text-left">
                  <div className="flex flex-wrap items-center justify-start">
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Kindergarten"
                        />
                        <span className="label-text text-xs">Early Childhood</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Primary"
                        />
                        <span className="label-text text-xs">Primary</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Secondary"
                        />
                        <span className="label-text text-xs">Secondary</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="K-12"
                        />
                        <span className="label-text text-xs">K-12</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Special Education"
                        />
                        <span className="label-text text-xs">Special Education</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="TAFE/Vocational"
                        />
                        <span className="label-text text-xs">TAFE/Vocational</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Higher Education"
                        />
                        <span className="label-text text-xs">Higher Education</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Tutoring"
                        />
                        <span className="label-text text-xs">Tutoring</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("schoolType")}
                          type="radio"
                          className="radio  radio-xs mr-1"
                          value="Other Workplaces"
                        />
                        <span className="label-text text-xs">Other Workplaces</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-[20%] flex justify-end font-bold">
                  <div className="relative w-[8rem] h-[5rem] mr-1 mt-2">
                    {logo ? (
                      <img
                        src={`https://academicjobs.s3.amazonaws.com/img/university-logo/${logo}`}
                        alt={defaultValues?.company_name}
                        className="w-full h-full object-contain rounded-md bg-white"
                      />
                    ) : (
                      <span className="absolute top-0 right-0 text-right font-bold px-2 py-1">Logo</span>
                    )}
                  </div>
                </div>
                <div className="w-[80%] text-left justify-center pt-4">
                  {employer ? (
                    <a className="text-gray-600 underline hover:text-blue-600" target="_blank" href={`https://www.google.com/search?tbm=isch&q=${encodeURIComponent(employer.company_name)} Logo`}>Google image link: {employer.company_name}</a>
                  ) : (
                    <form
                      action="https://www.google.com/search"
                      method="get"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <input
                        type="hidden"
                        name="tbm"
                        value="isch"
                      />
                      <input
                        type="text"
                        name="q"
                        placeholder="Search Google Images"
                        className="border mr-6"
                      />
                      <button className="border hover:bg-gray-600 hover:text-white" type="submit">Search</button>
                    </form>
                  )}
                  <div className="w-[80%] text-left justify-center pt-4">
                    <div class="w-full">
                      <InputBlock4 type="text" field="logo" label="Logo" register={register} errors={errors} forceClass="" />
                      <input type="file" id="fileInput" name="avatar"  {...avatarField} onChange={onUploadAvatar} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">AJ Admin Agent</label>
                <div className="w-[50%] text-left">
                  <select id="userDropdown"
                    {...register("AJAdminAgent")}
                  >
                    <option value="Select an Agent">Select an Agent</option>
                    {users.map(user => (
                      <option key={user.id} value={user.id}>
                        {user.firstName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">
                  AJ Sales Agent
                </label>
                <div className="w-[50%] text-left">
                  <select id="userDropdown" {...register("AJSalesAgent")}>
                    <option value="">Select an Agent</option>
                    {[{ name: 'Grace', id: 105 }, { name: 'Riley', id: 106 }].map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <p className="font-bold underline pt-2">Main Contact</p>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">First Name</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="firstname1"
                    label="firstname1"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Last Name</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="lastname1"
                    label="lastname1"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Job Title</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="jobtitle1"
                    label="jobtitle1"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Email</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="email1"
                    label="email1"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Phone Number</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="phone1"
                    label="phone1"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <p className="font-bold underline pt-2">Secondary Contact</p>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">First Name</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="firstname2"
                    label="firstname2"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Last Name</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="lastname2"
                    label="lastname2"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Job Title</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="jobtitle2"
                    label="jobtitle2"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Email</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="email2"
                    label="email2"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  mb-8 font-bold">Phone Number</label>
                <div className="w-[50%] text-left">
                  <InputBlock4
                    type="text"
                    field="phone2"
                    label="phone2"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                </div>
              </div>
              <div className="font-bold underline">Location Details</div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Institution-Location</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="location" label="Institution Location" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Country
                </label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="country" label="Country" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">State
                </label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="state" label="State" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">City
                </label>
                <div className="w-[50%] text-left ">
                  <InputBlock4 type="text" field="city" label="City" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Suburb</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="suburb" label="suburb" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Postal Code</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="postalCode" label="postalCode" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="mt-[2rem] font-bold text-[#e74b7f]">Employer Website/Socials</div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Website</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="website" label="website" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Employer Job Page URL</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="employerPageURL" label="employerPageURL" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Email</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="email" label="email" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="mt-[2rem] font-bold text-[#e74b7f]">Others</div>
              {/* <div className="flex gap-4">
            <label className="w-[30%] text-right  font-bold">Key Employer Contacts</label>
            <div className="w-[50%] text-left">
              <InputBlock4 type="text" field="KeyEmployerContacts" label="KeyEmployerContacts" register={register} errors={errors} forceClass="" />
            </div>
          </div> */}
              {/* <div className="flex gap-4">
            <label className="w-[30%] text-right  font-bold">School Type</label>
            <div className="w-[50%] text-left">
              <InputBlock4 type="text" field="schoolType" label="schoolType" register={register} errors={errors} forceClass="" />
            </div>
          </div> */}
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Personal Email</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="personalEmail" label="personalEmail" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">LinkedIn</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="LinkedIn" label="LinkedIn" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">School Level</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="schoolLevel" label="schoolLevel" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Phone</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="phone" label="phone" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Indicator</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="indicator" label="indicator" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Total Staff</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="totalStaff" label="totalStaff" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Main School Campus</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="mainSchoolCampus" label="mainSchoolCampus" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Sector</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="sector" label="sector" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Metro Country</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="metroCountry" label="metroCountry" register={register} errors={errors} forceClass="" />
                </div>
              </div>
              <div className="flex gap-4">
                <label className="w-[30%] text-right  font-bold">Job Title</label>
                <div className="w-[50%] text-left">
                  <InputBlock4 type="text" field="jobtitle" label="jobtitle" register={register} errors={errors} forceClass="" />
                </div>
              </div>

              {/* 
              <label className="w-full font-bold text-[#e74b7f] mt-[2rem]">Sales Notes</label>
              <div className="flex gap-4">
                <label className=" text-right  font-bold">Sales Notes Update Date</label>
                <div className="w-[50%] text-left">
                  {new Date(employer?.salesNoteUpdateDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                  <InputBlock4 type="text" field="salesNoteUpdateDate" label="salesNoteUpdateDate" register={register} errors={errors} forceClass="" />
                </div>
              </div> */}
              {/* <textarea
                id="description-input"
                name="07_Nominee_Description"
                className="w-full px-4 py-3 0 border border-black  rounded-lg text-black focus:outline-none focus:border-orange-500"
                placeholder="Please write sales notes here"
                {...register("salesNote")}
                onChange={handleSalesNoteChange}
              /> */}
              {/* <button
            className="btn btn-success w-[80%] mx-auto"
            onClick={async (e) => {
              // const response = await updateEmployer(record);
              // console.log(response)
              // if (response) {
              //     e.target.innerText = 'Changes SAVED!';
              //     setTimeout(() => {
              //         e.target.innerText = 'Save Changes';
              //     }, 5000);
              // }
            }}
          >
            Save Changes
          </button> */}

              <label className="w-full font-bold text-[#e74b7f] mt-10">Company Description</label>
              <ReactQuill modules={modules} value={employer?.company_description} className=""
                //onChange={handleChange(value, "company_description")}
                onChange={(value) => {
                  setValue("company_description", value)
                  // handleChange(value, "company_description")
                }}
              />
              <button
                className="btn btn-success w-full mx-auto"
                onClick={async (e) => {
                  // const response = await updateEmployer(record);
                  // console.log(response)
                  // if (response) {
                  //     e.target.innerText = 'Changes SAVED!';
                  //     setTimeout(() => {
                  //         e.target.innerText = 'Save Changes';
                  //     }, 5000);
                  // }
                }}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>)}

      {activeTab === "sales" && employer?.id >= 0 && (
        <div className="sales-tab">
          <SalesNotes id={employer?.id} jobId={activeJobId} />
          <OtherContacts id={employer?.id} />
        </div>
      )}

      {activeTab === "invoice" && employer?.id >= 0 && (
        <div className="invoice-tab">
          <CreateInvoice employer={employer} />
        </div>
      )}
      {activeTab === "users" && employer?.id >= 0 && (
        <div className="user-tab">
          <AddEditUsers id={employer?.id} userType={"employer"} employer_name={employer?.company_name} employer_details={employer} />
        </div>
      )}
    </div>

  );

  return <div className="overflow-y w-full">{content}</div>;
};

export default EmployerAddEdit;
