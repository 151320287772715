import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContactCard from "../components/ContactCard";
import { useSelector, useDispatch } from "react-redux";
import { setJobCredits } from "../store/postsSlice";
import {
  useGetUserByIdQuery,
  useGetEmployerQuery,
  useUpdateEmployerMutation,
} from "../store/apiSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AdminHome_Customer(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [
    updateEmployer,
    {
      isLoading: isLoadingUpdateEmployer,
      isSuccess: isSuccessUpdateEmployer,
      isError: isErrorUpdateEmployer,
      error: errorUpdateEmployer,
    },
  ] = useUpdateEmployerMutation();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { data } = useGetEmployerQuery(userInfo.employer_id);

  const contactCardData1 = {
    imageSrc: "https://www.academicjobs.com/about-us/team/jarrod-kanizay.jpg",
    name: "Jarrod",
    title: "Your account manager! Contact him with any queries you may have.",
    contactInfo: ["jarrod@teachingjobs.com.au", "0430 017 783"],
  };

  const { data: userData } = useGetUserByIdQuery({ id: userInfo.id });

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (data?.company_description) {
      setDescription(data.company_description);
    }
  }, [data]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = () => {
    updateEmployer({
      id: userInfo.employer_id,
      company_description: description,
    });
    setIsEditing(false);
  };

  return (
    <>
      {!data ? (
        <div>Loading...</div>
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-5">
          <div className="col-span-1 md:col-span-4">
            <div className="flex">
              <h2 className="text-gray-500 font-bold text-4xl mb-4">
                School Profile
              </h2>
              <button
                onClick={isEditing ? handleSaveClick : handleEditClick}
                className="btn btn-aj ml-4 mt-4"
              >
                {isEditing ? "Save" : "Edit"}
              </button>
              {isEditing && (
                <button
                  onClick={handleEditClick}
                  className=" btn bg-grey-500 text-grey-100 rounded-full ml-4 mt-4"
                >
                  Cancel
                </button>
              )}
            </div>
            {isEditing ? (
              <ReactQuill
                value={description}
                onChange={setDescription}
                className="mb-4 overflow-y-auto custom-scrollbar"
              />
            ) : (
              <div
                className="w-full"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
          <div className="col-span-1 md:col-span-1">
            <ContactCard
              imageSrc={contactCardData1.imageSrc}
              name={contactCardData1.name}
              title={contactCardData1.title}
              contactInfo={contactCardData1.contactInfo}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AdminHome_Customer;
