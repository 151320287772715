import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PricingTable from "../components/PricingTable"; // Importing the default export

const ChooseJobPack = () => {
  return (
    <main className="container">
      <PricingTable />
    </main>
  );
};

export default ChooseJobPack;
