import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUserMutation } from '../../store/apiSlice'; // Ensure correct import path
import PasswordReset from '../../components/PasswordReset';
import swal from 'sweetalert';

const ProfileSettings = () => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [formData, setFormData] = useState(userInfo);
    const [updateUser] = useUpdateUserMutation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await updateUser(formData).unwrap();
            swal('Success', 'User details updated successfully', 'success')
        } catch (error) {
            swal('Error', 'Failed to update user details', 'error');
        }
    };

    return (
        <div className="mt-12 flex items-center justify-center ">
            <div className="w-full max-w-5xl bg-white rounded-lg shadow-md p-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
                {/* User Details Form */}
                <form onSubmit={handleSubmit} className="space-y-6">
                    <h2 className="text-3xl font-semibold mb-6 text-center lg:text-left">User Details</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName || ''}
                                onChange={handleChange}
                                className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName || ''}
                                onChange={handleChange}
                                className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email || ''}
                            onChange={handleChange}
                            className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Phone</label>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone || ''}
                            onChange={handleChange}
                            className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
                        />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">State</label>
                            <input
                                type="text"
                                name="state"
                                value={formData.state || ''}
                                onChange={handleChange}
                                className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Post Code</label>
                            <input
                                type="text"
                                name="postCode"
                                value={formData.postCode || ''}
                                onChange={handleChange}
                                className="w-full input input-sm input-bordered focus:outline-none focus:border-orange-500"
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="w-full md:w-auto mx-auto py-2 px-6 btn btn-aj block"
                    >
                        Save Changes
                    </button>
                </form>

                {/* Password Reset Section */}
                <div className="border-t lg:border-t-0 lg:border-l border-gray-300 pt-8 lg:pt-0 lg:pl-8">
                    <h2 className="text-3xl font-semibold mb-6 text-center lg:text-left">Password Reset</h2>
                    <PasswordReset userId={userInfo.id} />
                </div>
            </div>
        </div>
    );
};

export default ProfileSettings;
