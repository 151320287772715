import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setEmployer } from '../store/postsSlice';
import { useNavigate } from 'react-router-dom';

const EmployersList = ({ data }) => {
  const [open, setOpen] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleCollapse = (employerId) => {
    setOpen((prevState) => ({
      ...prevState,
      [employerId]: !prevState[employerId],
    }));
  };

  const handleEmployerClick = (employer) => {
    dispatch(setEmployer({ employer_id: employer.id, company_name: employer.company_name, logo: employer.logo }));
    navigate(`/employers-center/${employer.id}/`, { state: { activeTab: "sales" } });
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  return (
    <div className="container mx-auto mt-5">
      <div className="flex flex-wrap -mx-4">
        {Object.keys(data).map((country) => (
          <div key={country} className="w-full md:w-1/2 px-4 mb-8">
            <h2 className="text-2xl font-bold mb-4">{country}</h2>
            {Object.values(data[country]).map((employer) => (
              <div key={employer.id} className="mb-6 border rounded-lg p-4 shadow">
                <div className="flex justify-between items-center">
                  <div>
                    <h2
                      className="text-lg font-semibold underline hover:text-sky-500 text-grey-800 cursor-pointer"
                      onClick={() => handleEmployerClick(employer)}
                    >
                      {employer.company_name}
                    </h2>
                  </div>
                  <button
                    onClick={() => toggleCollapse(employer.id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    {open[employer.id] ? 'Hide Jobs' : 'Show Jobs'}
                  </button>
                </div>
                {open[employer.id] && (
                  <div className="mt-4">
                    {employer.jobs.map((job) => (
                      <div key={job.id} className="mb-4 border-t pt-4">
                        <h4 className="text-lg font-medium">
                          {job.title} <span className="text-sm text-gray-500">(exp: {formatDate(job.expiration_date)})</span>
                        </h4>
                        {job.firstName1 && (
                          <div className="mt-2">
                            <p>
                              <strong>{job.firstName1} {job.lastName1}</strong> - {job.jobTitle1}
                            </p>
                            {job.phone1 && (
                              <p className="text-blue-500">
                                <a href={`tel:${job.phone1}`}>Call: {job.phone1}</a>
                              </p>
                            )}
                            {job.email1 && (
                              <p className="text-blue-500">
                                <a href={`mailto:${job.email1}`}>Email: {job.email1}</a>
                              </p>
                            )}
                          </div>
                        )}
                        {job.firstName2 && (
                          <div className="mt-2">
                            <p>
                              <strong>{job.firstName2} {job.lastName2}</strong> - {job.jobTitle2}
                            </p>
                            {job.phone2 && (
                              <p className="text-blue-500">
                                <a href={`tel:${job.phone2}`}>Call: {job.phone2}</a>
                              </p>
                            )}
                            {job.email2 && (
                              <p className="text-blue-500">
                                <a href={`mailto:${job.email2}`}>Email: {job.email2}</a>
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployersList;
