import React from "react";
import { Link, useLocation } from "react-router-dom";

const DashboardCard = ({
  title = "Applications",
  iconPath = "/icons/heart.svg",
  href = "/my_life",
  iconClass,
  hidden = false,
  onClick, // New prop for onClick functionality
}) => {
  const location = useLocation();
  const isActive = location.pathname === href;
  const activeClass = isActive ? "active" : "";

  return (
    <>
      {!hidden && (
        <Link
          to={href}
          role="alert"
          className={`dashboard_button alert bg-white hover:bg-slate-100 border-solid border-1 border-slate-300 ${activeClass}`}
          onClick={onClick} // Triggering onClick function
        >
          <img
            src={iconPath}
            alt="icon"
            width={30}
            height={30}
            className={`${iconClass}`}
          />
          <span>{title}</span>
        </Link>
      )}
    </>
  );
};

export default DashboardCard;
