import React, { useState, useEffect, useRef } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SearchResults from './SearchResults';

import { Link } from 'react-router-dom'

const Graduates1 = () => {  window.location.replace("https://www.academicjobs.com/graduate");
  const handleFormSubmit = (event) => {

    event.preventDefault();
    const a = {}
    if (keyWordRef.current.value.trim()) a.q = keyWordRef.current.value.trim()
    if (locationRef.current.value.trim()) a.l = locationRef.current.value.trim()
    navigate("/jobs/", { state: { q: keyWordRef.current.value.trim(), l: locationRef.current.value.trim() } });
  }

  const [page, setPage] = useState(0);
  const navigate = useNavigate()
  const keyWordRef = useRef('')
  const locationRef = useRef('')
  return (


    <div className="container mx-auto">
      <Helmet>
        <title>Graduate Jobs</title>
        <meta name="description" content="Looking for a flexible and rewarding job ? Browse hundreds of graduate jobs in various roles and departments at your university. Apply today and gain valuable work experience and skills. " />
        <meta name="keywords" content="Graduate Jobs , Graduate academic Jobs" />
      </Helmet>
      <div className="blurb text-left py-8 ">
        <h1 className=" font-bold text-lg md:text-2xl py-4 px-7 bg-[#00aeef] text-white rounded-full shadow-xl ">Graduate Jobs</h1>

        <div className="newLine mb-4">
          <p className="font-semibold"></p>
          <p className="font-semibold"></p>
        </div>
        <div className="newLine">
          <div className="py-4 px-7 rounded-2xl ">
          <p className="pb-2">
          All graduate job openings from the top universities are available on our site. Apply on our website for amazing graduate roles worldwide, since our academic institutions are always looking for the best graduate jobs.</p>
            <p >
              Graduate jobs are temporary or part-time positions in a variety of departments and functions in a university or research institute. These positions give graduates the chance to improve their academic and social networks, earn money, obtain useful work experience, and develop employability skills. Graduate positions can be found in a variety of fields, such as research, teaching, administration, customer service, events, marketing, IT, and libraries. You can locate graduate programs, part-time and casual work, and unique internship possibilities in Australia and throughout the world if you're interested in seeking graduate jobs. You can obtain important work experience and abilities that will benefit you in your future career by applying for these opportunities.  </p>
          </div>
          <div className="newLine mb-2"></div>
   




         





      </div>


      <div className="container mx-auto px-4 sm:px-6 lg:px-8 mb-4">
        <div className="max-w-screen-xl mx-auto">
          <form className="flex flex-col  gap-2 md:flex-row md:gap-2 mx-18 w-full " onSubmit={handleFormSubmit}>
            <input
              type="text"
              className="text-center md:w-[41%] md:text-left px-4 py-2 border border-[#00aeef] text-gray-500 rounded-md focus:ring-orange-500 focus:border-orange-500"
              placeholder="Keyword"
              ref={keyWordRef}
            //defaultValue={name}
            />
            <input
              type="text"
              className="text-center md:w-[41%] md:text-left px-4 py-2 border border-[#00aeef] text-gray-500 rounded-md focus:ring-orange-500 focus:border-orange-500"
              placeholder="Location"
              ref={locationRef}
            />
            <button
              className="bg-[#00aeef] hover:bg-orange-600 text-white py-2 px-6 rounded-md focus:ring-2 focus:ring-orange-300"
              type="submit"
            >
              Find Jobs
            </button>
          </form>
        </div>
      </div>



      <SearchResults q={{ q: "graduate" || 0 }} />
    </div> </div>

  );
};

export default Graduates1;