// ContactCard.js
import React from 'react';

function ContactCard({ imageSrc, name, title, contactInfo }) {
  return (
    <div className="contact-card bg-white p-4 shadow-lg rounded-lg mt-4">
      <img src={imageSrc} alt={`${name}'s profile`} className="w-26 h-32 rounded-full mx-auto" />
      <h3 className="text-xl font-bold text-center mt-4">{name}</h3>
      <p className="text-center text-gray-600">{title}</p>
      <div className="mt-4 text-center text-gray-800">
        {contactInfo.map((info, index) => (
          <p key={index}>{info}</p>
        ))}
      </div>
    </div>
  );
}

export default ContactCard;
